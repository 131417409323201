<template>
  <div class="mt-5 mt-xl-4 custom-height position-relative">
    <img src="../../assets/images/contact-map-locations.webp" class="img-map" alt="..." />
    <img
      src="../../assets/images/contact-white-rounded-bg.svg"
      class="img-rounded-white"
      alt="..."
    />
    <div class="card-position">
      <div class="card">
        <div class="card-body">
          <div class="heading-04 text-neutral-07 text-center mb-4">Resetează parola</div>
          <Form
            :validation-schema="schema"
            @submit="ChangePassword"
            ref="signUpModalRef"
            v-slot="{ errors }"
          >
            <div class="mb-3" :class="{ 'invalid-input': errors.password }">
              <label for="password" class="text-neutral-07 text-button-02 mb-2">Parolă nouă</label>
              <div class="input-group right">
                <Field
                  v-model="password"
                  :type="[showPassword ? 'text' : 'password']"
                  name="password"
                  id="password"
                  placeholder="Parola"
                  class="form-control"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showPassword = true"
                  @pointerup="showPassword = false"
                >
                 <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="password" class="error-message" />
            </div>

            <div class="mb-3" :class="{ 'invalid-input': errors.confirmPassword }">
              <label for="confirmPassword" class="text-neutral-07 text-button-02 mb-2"
                >Confirmare parola</label
              >
              <div class="input-group right">
                <Field
                  v-model="confirmPassword"
                  :type="[showConfirmedPassword ? 'text' : 'password']"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Parola"
                  class="form-control"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showConfirmedPassword = true"
                  @pointerup="showConfirmedPassword = false"
                >
                 <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showConfirmedPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="confirmPassword" class="error-message" />
            </div>
            <button class="button btn-medium-wider btn-primary-solid text-button-02 w-100">
              Resetează parola
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ChangePasswordView',
  data() {
    return {
      showPassword: false,
      showConfirmedPassword: false,
      password: '',
      confirmPassword: '',
    };
  },
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  methods: {
    ChangePassword() {
      if (this.$route.query.token && this.$route.query.email) {
        this.$store.state.loader = true;
        const verification = {
          Email: this.$route.query.email,
          Code: this.$route.query.token.toString().replace(/\s/g, '+'),
          Password: this.password,
          ConfirmPassword: this.confirmPassword,
        };
        this.$axios
          .post('/Auth/resetPassword', verification)
          .then(() => {
            this.$store.state.loader = false;
            this.$swal
              .fire({
                title: 'Success!',
                text: 'Parola a fost schimbată.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({ name: 'HomePage' });
                }
              });
          })
          .catch(() => {
            this.$store.state.loader = false;
            this.$utils.ToastNotify('error', 'Something went wrong.');
          });
      } else this.$route.push({ name: 'HomePage' });
    },
    VerifyToken() {
      if (this.$route.query.token && this.$route.query.email) {
        this.$store.state.loader = true;
        const verification = {
          Email: this.$route.query.email,
          Code: this.$route.query.token.toString().replace(/\s/g, '+'),
          Password: this.password,
          ConfirmPassword: this.confirmPassword,
        };
        this.$axios
          .post('/Auth/verifyToken', verification)
          .then(() => {
            this.$store.state.loader = false;
          })
          .catch(() => {
            this.$store.state.loader = false;
            this.$utils.ToastNotify('error', 'Link-ul a fost deja utilizat.');
            this.$route.push({ name: 'HomePage' });
          });
      } else this.$route.push({ name: 'HomePage' });
    },
  },
  created() {
    if (!(this.$route.query.token && this.$route.query.email)) {
      this.$route.push({ name: 'HomePage' });
    }
    this.VerifyToken();
  },
  computed: {
    schema() {
      return yup.object({
        password: yup
          .string()
          .min(6, 'Parola trebuie să conțină cel puțin 6 caractere')
          .required('Parola este obligatorie.')
          .test(
            'password-complecity',
            'Parola trebuie să conțină minimum o majusculă, un caracter special și o cifră.',
            (password) => {
              const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~]/;
              const numberRegex = /[0-9]/;
              const uppercaseRegex = /[A-Z]/;
              return (
                specialCharRegex.test(password) &&
                numberRegex.test(password) &&
                uppercaseRegex.test(password)
              );
              // eslint-disable-next-line comma-dangle
            }
          ),
        confirmPassword: yup
          .string()
          .required('Confirmarea parolei este obligatorie.')
          .test(
            'check-password',
            'Parolele nu se potrivesc.',
            // eslint-disable-next-line comma-dangle
            (confirmPassword) => confirmPassword === this.password
          ),
        // acceptTerms: yup
        //   .boolean()
        //   .required()
        //   .test('accept-terms', 'Va rugam acceptati termeni si conditiile!', (terms) => terms),
      });
    },
  },
  //   created() {
  //     this.ChangePassword();
  //   },
};
</script>
<style scoped>
@media (min-width: 576px) {
  .custom-height {
    height: 85vh;
  }
}
.card-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 0px 5px 60px 0px #0000001a;
  border-radius: 24px;
  border: 0px;
  max-width: 435px;
  width: 100%;
  min-height: 454px;
}

.img-map {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.img-rounded-white {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
